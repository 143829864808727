<template>
  <div id="projects-page">
    <app-box width="large">
      <template #legend>Projects</template>
      <template #title>{{ cProjects.length ? 'Manage your projects' : 'Create your first project' }}</template>
      <div class="mb-4 md:mb-6" :class="[cProjects.length ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cProjects.length || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          <template v-if="cProjects.length">
            Here are all the projects you have added.
            You can archive a project if you're not working on it but want to keep its data for reporting purposes.
          </template>
          <template v-else-if="!minimal">
            Before you get started working on your tasks, tracking time, invoicing clients, and all that fun stuff, you need a project.
            Go ahead and create your first project by clicking on the button below.
          </template>
        </p>
        <div v-if="!focusUuid" :class="[cProjects.length ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New project</span>
          </app-button>
        </div>
      </div>
      <app-table v-if="cProjects.length" class="-mb-8 md:mb-0 -mx-5 md:mx-0" :class="minimal ? [focusUuid ? '-mt-15' : '-mt-1 md:-mt-14'] : []">
        <app-thead>
          <app-tr>
            <app-th>
              {{ cProjects.length }} Project{{ cProjects.length !== 1 ? 's' : '' }}<span class="hidden md:inline ml-0.5 text-gray-500 font-normal"> | {{ active }} active | {{ archived }} archived</span>
            </app-th>
            <app-th>
              Billing
            </app-th>
            <app-th class="hidden md:table-cell">
              Status
            </app-th>
            <app-th />
          </app-tr>
        </app-thead>
        <app-tbody>
          <transition-group move-class="transition-transform duration-500" enter-active-class="transition-colors duration-slow" enter-from-class="bg-yellow-200" enter-to-class="bg-transparent" leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <tr v-for="project in cProjects" :id="project.lastAdded ? 'last-added' : null" :key="project.uuid">
              <app-td :class="{ 'opacity-50': !project.active }">
                <div class="md:flex md:items-center">
                  <div class="hidden md:block md:flex-shrink-0 md:h-10 md:w-10">
                    <template v-if="clickedProjectUuid == project.uuid && uploadProgress > 0 && uploadProgress < 100">
                      <div :style="`background: linear-gradient(90deg, #c6f6d5 ${uploadProgress}%, #c6f6d5 ${uploadProgress-20}%, #ffffff ${uploadProgress+20}%, #ffffff ${uploadProgress}%);`" class="pt-2.5 border border-gray-400 h-10 w-10 rounded-full text-center text-xs text-gray-600 font-semibold font-mono">
                        {{ uploadProgress }}<span class="font-normal">%</span>
                      </div>
                    </template>
                    <template v-else>
                      <a href="#" @click.prevent="onImageClick(project)">
                        <img v-if="project.image" class="border border-gray-400 h-10 w-10 rounded-full" :class="[project.active ? ['hover:border-gray-500 hover:shadow'] : ['filter-grayscale cursor-default']]" :src="`https://res.cloudinary.com/dj6vx62wj/image/upload/w_38,h_38,c_fill/${project.image}.png`" :alt="project.name">
                        <div v-else v-tooltip:[tooltipData(project)]="tooltipText" class="border border-gray-400 h-10 w-10 rounded-full bg-white" :class="[project.active ? ['hover:border-gray-500 hover:shadow'] : ['filter-grayscale cursor-default']]" />
                      </a>
                    </template>
                  </div>
                  <app-td-content class="md:ml-4">
                    <template #first>{{ project.name }}</template>
                    <template #second>{{ project.description || '-' }}</template>
                  </app-td-content>
                </div>
              </app-td>
              <app-td :class="{ 'opacity-50': !project.active }">
                <app-td-content>
                  <template #first>{{ project.type }}</template>
                  <template #second>{{ project.type !== 'None' ? `${formatNumber(project.amount)} ${project.currency}` : '-' }}</template>
                </app-td-content>
              </app-td>
              <app-td class="hidden md:table-cell" :class="{ 'opacity-50': !project.active }">
                <span v-if="project.active" class="w-20 inline-flex items-center pl-1.5 py-0.5 text-xs font-medium bg-gradient-to-r from-green-300 to-gray-200 lg:to-transparent text-green-700 border-l-4 border-green-400">
                  Active
                </span>
                <span v-else class="w-20 inline-flex items-center pl-1.5 py-0.5 text-xs font-medium bg-gradient-to-r from-gray-300 to-gray-200 lg:to-transparent text-gray-700 border-l-4 border-gray-400">
                  Archived
                </span>
              </app-td>
              <app-td class="w-1">
                <app-dropdown :options="project.options" entity="project" :count-url="`api/items/count?projectUuid=${project.uuid}`" @click="handleChoice(project.uuid, $event)" />
              </app-td>
            </tr>
          </transition-group>
        </app-tbody>
      </app-table>
      <input id="file-input" class="hidden" type="file" @change="onFileSelection">
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ editUuid ? "Edit project" : "Create new project" }}
          </template>
          <app-form @submit.prevent="submitForm">
            <app-form-field>
              <app-label id="name">
                Name
              </app-label>
              <app-input id="name" v-model.trim="form.fields.name" maxlength="45" />
            </app-form-field>
            <app-form-field>
              <app-label id="description">
                Description
              </app-label>
              <app-input id="description" v-model.trim="form.fields.description" maxlength="45" />
            </app-form-field>
            <div class="flex">
              <app-form-field class="w-1/3">
                <app-label id="billing-type">
                  Billing type
                </app-label>
                <app-select id="billing-type" v-model="form.fields.type" :options="types" />
              </app-form-field>
              <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="form.fields.type !== 'None'" class="flex w-2/3">
                  <app-form-field class="ml-2 w-3/5">
                    <app-label id="amount">
                      Amount
                    </app-label>
                    <app-input id="amount" v-model="form.fields.amount" type="number" min="0" max="99999999" />
                  </app-form-field>
                  <app-form-field class="ml-2 w-2/5">
                    <app-label id="currency">
                      Currency
                    </app-label>
                    <app-select id="currency" v-model="form.fields.currency" :options="currencies" />
                  </app-form-field>
                </div>
              </transition>
            </div>
            <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
              {{ editUuid ? "Update" : "Create" }}
            </app-button>
          </app-form>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Projects",
  data() {
    return {
      clickedProjectUuid: 0,
      editUuid: "",
      uploadProgress: 0,
      form: {
        fields: {
          currency: "",
          amount: "",
          type: "",
          name: "",
          description: "",
        },
      },
      account: {},
      projects: [],
      showModal: false,
      tooltipText: `Upload an image for this project. Use a square image, 250x250 pixels minimum, for best results.`,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["focusUuid", "loading", "minimal"]),
    cProjects() {
      return [...this.projects]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          if (a.active !== b.active) return b.active - a.active;
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .filter((p) => p.uuid === this.focusUuid || this.focusUuid === "")
        .map((p) => {
          p.options = [];
          if (p.active) {
            p.options.push("Edit");
            if (this.projects.length !== 1 && !this.focusUuid) {
              p.options.push("Focus mode");
            }
          }
          if (p.uuid === this.focusUuid) {
            p.options.push("Exit focus mode");
          } else {
            p.options.push(p.active ? "Archive" : "Activate");
            if (!p.active) {
              p.options.push("Delete");
            }
          }
          return p;
        });
    },
    active() {
      return this.cProjects.reduce((acc, cur) => (cur.active ? ++acc : acc), 0);
    },
    archived() {
      return this.cProjects.reduce(
        (acc, cur) => (!cur.active ? ++acc : acc),
        0
      );
    },
    invalidForm() {
      const { amount, name, type } = this.form.fields;
      if (name === "") return true;
      const noAmount = amount === "" || amount === "0";
      if (type !== "None" && noAmount) return true;
      return false;
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    await this.getData();
  },
  methods: {
    formatNumber: U.formatNumber,
    tooltipData(project) {
      return { show: !this.minimal && project.active, position: "top" };
    },
    async getData() {
      const [{ accounts }, { projects }] = await U.apiAll([
        { url: "api/accounts" },
        { url: "api/projects" },
      ]);
      this.account = accounts[0];
      this.projects = projects;
    },
    handleChoice(uuid, option) {
      if (option === "Focus mode") {
        this.$store.commit("state", { key: "focusUuid", value: uuid });
      } else if (option === "Exit focus mode") {
        this.$store.commit("state", { key: "focusUuid", value: "" });
      } else if (option === "Edit") {
        this.openModal(uuid);
      } else if (option === "Archive" || option === "Activate") {
        const active = option === "Activate";
        this.projects = U.patch("project", this.projects, uuid, { active });
      } else if (option === "Delete") {
        this.projects = U.delete("project", this.projects, uuid);
        const newState = { key: "projectCount", value: this.projects.length };
        this.$store.commit("state", newState);
      }
    },
    openModal(uuid) {
      this.editUuid = uuid || "";
      this.types = U.types();
      this.currencies = U.currencies();
      for (const field in this.form.fields) {
        let defaultValue, project;
        if (this.editUuid) {
          project = { ...this.cProjects.find((p) => p.uuid === this.editUuid) };
        } else {
          defaultValue = "";
          if (field === "type") {
            defaultValue = "Hourly";
          } else if (field === "currency") {
            defaultValue = this.account.currency;
          }
        }
        this.form.fields[field] = project ? project[field] : defaultValue;
      }
      this.showModal = true;
    },
    submitForm() {
      if (this.invalidForm) return;
      let { amount, type, currency, description, name } = this.form.fields;
      const obj = { amount, type, currency, description, name };
      obj.amount = parseInt(obj.amount);
      obj.description = obj.description || null;
      if (this.editUuid) {
        const uuid = this.editUuid;
        this.projects = U.patch("project", this.projects, uuid, obj);
      } else {
        obj.active = true;
        obj.image = null;
        this.projects = U.post("project", this.projects, obj);
        U.scrollToLastAdded();
        const newState = { key: "projectCount", value: this.projects.length };
        this.$store.commit("state", newState);
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
    onImageClick(project) {
      if (project.active) {
        this.clickedProjectUuid = project.uuid;
        document.getElementById("file-input").click();
      }
    },
    onFileSelection(event) {
      const file = event.target.files[0];
      if (file) {
        let url = "https://api.cloudinary.com/v1_1/dj6vx62wj/image/upload";
        let body = new FormData();
        let uploadProgress = 0;
        const xhr = new XMLHttpRequest();
        body.append("file", file, file.name);
        body.append("upload_preset", "s4askt42");
        xhr.open("post", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            uploadProgress = parseInt((e.loaded / e.total) * 100);
          }
        };
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const uuid = this.clickedProjectUuid;
            const obj = { image: JSON.parse(xhr.responseText).public_id };
            this.projects = U.patch("project", this.projects, uuid, obj);
            document.getElementById("file-input").value = "";
            this.$store.commit("addTooltip", U.hash(this.tooltipText));
          }
        };
        const uploadStarted = Date.now();
        this.projects.find((p) => p.uuid == this.clickedProjectUuid).image = "";
        xhr.send(body);
        const progressInterval = setInterval(() => {
          const diff = Date.now() - uploadStarted;
          const delay = 20;
          if (uploadProgress * delay > diff) {
            this.uploadProgress = Math.round(diff / delay);
          } else {
            this.uploadProgress = uploadProgress === 100 ? 99 : uploadProgress;
          }
          if (uploadProgress === 100 && diff > delay * 100) {
            this.uploadProgress = 0;
            clearInterval(progressInterval);
          }
        }, 150);
      }
    },
  },
};
</script>
